<div class="header">
  <spb-logo [routePath]="['home']"
            [title]="service.siteName$()"></spb-logo>
  @if ((service.logInState$ | async)) {
    <div class="menu">
      <span>{{ (service.logInState$ | async)?.name }}</span>
      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  }
</div>

<!-- Add other fancy navigations here -->
<mat-menu #menu="matMenu">
  <button (click)="service.logout()" mat-menu-item>Logga ut</button>
</mat-menu>
