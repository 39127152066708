import {HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from './application/types'
import {authGuard} from './application/auth.guard'
import {Routes} from '@angular/router'

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    // Remember the export default -- satisfies routes...
    loadChildren: () => import('./home/home.routes'),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./common/login/login.component')
      .then(c => c.LoginComponent)
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
] satisfies Routes