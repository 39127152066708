import {APP_INITIALIZER, ApplicationConfig} from '@angular/core'
import {ConfigService} from './services/config.service'
import {environment} from '../environments/environment'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {provideRouter, withHashLocation} from '@angular/router'
import {ENVIRONMENT} from './application/app'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import routes from './app.routes'

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    // Used by config service and has to be provided in "root"
    {provide: ENVIRONMENT, useValue: environment},
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAnimationsAsync()
  ]
}