import {Component, inject} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatButton} from '@angular/material/button'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe} from '@angular/common'
import {MatFormField, MatPrefix, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [ThemeModule, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, AsyncPipe, MatFormField, MatInput, MatSuffix, MatPrefix]
})
export class HeaderComponent {
  public readonly service = inject(ConfigService)
}
